<script setup lang="ts">
import { useRoute } from '#app'

interface BaseNavLinkProps {
  to: string
  disabled?: boolean
  icon?: string
  badge?: string
  active?: boolean
  custom?: boolean
  external?: boolean
}
const props = defineProps<BaseNavLinkProps>()
defineEmits(['click'])
const useRouter = useRoute()

const component = computed(() => props.custom ? 'div' : resolveComponent('nuxt-link'))
const isActive = computed(() => useRouter.path.includes(props.to) || props.active)
</script>

<template>
  <component
    :is="component"
    :class="{'bg-secondary':isActive, 'hover:bg-gray-100' : !isActive}"
    :to="to"
    :external="external"
    class="cursor-pointer rounded-xl flex align-baseline items-center transition-colors px-3"
    @click="$emit('click',$event)"
  >
    <div v-if="icon" class="w-6 h-6 flex items-center justify-center align-baseline mr-3">
      <BaseIcon size="md" :name="icon" class="w-6  h-6 self-center" />
    </div>
    <div class="inline-flex items-center w-full justify-between py-3 text-sm text-black">
      <slot />
      <div v-if="badge" class="h-4 px-1.5 py-1 bg-purple-300 rounded justify-start items-center gap-2.5 inline-flex">
        <div class=" text-white text-[11px] font-normal leading-tight">
          {{ badge }}
        </div>
      </div>
      <div v-if="external">
        <BaseIcon size="md" name="external-link" class="w-6 h-6 self-center" />
      </div>
    </div>
  </component>
</template>

<style>

</style>
