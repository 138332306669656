<template>
  <LazyProfileModal v-if="isProfileOpened" v-model="isProfileOpened" />
  <LazyLoginPopup
    v-if="authStore.isGuest"
    v-model="isSignUpPopupVisible"
    :title="currentPopup.title"
    :subtitle="currentPopup.subtitle"
    :img="currentPopup.img"
  />
  <transition
    enter-active-class="duration-100 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-100 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div
      v-show="isMenuOpen"
      class="flex-none h-screen max-h-screen pt-2 border-r-1 border-secondary-border overflow-y-auto
        top-0 left-0 z-50 bg-primary-background fixed xl:sticky w-full md:w-[360px]"
    >
      <div class="h-full flex flex-col justify-between mx-[20px] px-3">
        <div>
          <div class="mt-10 md:mt-[160px] xl:mt-10 mb-[100px] md:mb-10">
            <NuxtLink to="/library/" @click="onLinkClick">
              <img src="/laoshi-logo.svg" alt="logo">
            </NuxtLink>
            <base-button
              class="fixed xl:hidden p-[23px] z-50
                bottom-2 left-2 md:bottom-auto md:left-[20px] md:top-[37px]
                bg-primary-background invert hover:invert-0"
              rounded
              outlined
              @click="isShown = false"
            >
              <BaseIcon size="sm" name="close-outline" />
            </base-button>
          </div>

          <nav class="flex flex-col">
            <base-nav-link
              :active="$route.path.includes('/library') || $route.path.includes('wordlists')"
              class="my-1"
              icon="library"
              :to="libraryLink"
              @click="onLibraryOpen"
            >
              {{ $t('wordsLibrary') }}
            </base-nav-link>
            <base-nav-link
              class="my-1"
              icon="profile"
              to="/classrooms/"
              :custom="authStore.isGuest"
              @click="onLinkClick('students')"
            >
              {{ $t('myStudents') }}
            </base-nav-link>
            <base-nav-link
              badge="NEW"
              class="my-1"
              icon="chat-circle"
              to="/users/chat/"
            >
              {{ $t('chat') }}
            </base-nav-link>
            <base-nav-link
              class="my-1"
              icon="grammar"
              target="_blank"
              to="https://laoshi.io/grammar/en/"
              external
            >
              {{ $t('grammar') }}
            </base-nav-link>
            <base-card class="flex p-3 mt-10 cursor-pointer " @click="onProfileClick">
              <BaseAvatar size="sm" class="flex-shrink-0 w-6 h-6 self-center" :avatar="avatar" />
              <div class="flex flex-col pl-4">
                <div class="text-ellipsis whitespace-nowrap overflow-hidden w-44">
                  {{ authStore.isGuest ? $t('helloGuest'): authStore.user?.name }}
                </div>
                <div class="text-sm text-gray-500  flex flex-wrap overflow-hidden">
                  {{ authStore.isGuest ? $t('signUpForMoreFeatures') : $t('userProfileHint') }}
                </div>
              </div>
            </base-card>
          </nav>
        </div>
        <div class="mx-auto text-sm flex text-gray-500 flex-wrap pb-[112px] md:pb-10 pt-10 gap-3">
          <nuxt-link target="_blank" external :href="termsOfUseUrl(hostLocale)">
            {{ $t('termsOfUse') }}
          </nuxt-link>
          <nuxt-link target="_blank" external :href="`mailto:${contactUsEmail(hostLocale)}`">
            {{ $t('contactUs') }}
          </nuxt-link>
          <nuxt-link target="_blank" external :href="privacyPolicyUrl(hostLocale)">
            {{ $t('privacyPolicy') }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </transition>

  <BaseButton
    v-show="!isShown"
    rounded
    class="fixed xl:hidden
      bottom-2 left-2 md:bottom-auto md:left-[20px] md:top-[37px]
      p-[19px] border-[0.8px] border-secondary-border z-40"
    color="secondary"
    @click="isShown = true"
  >
    <BaseIcon size="md" name="burger" />
  </BaseButton>

  <!-- tablet overlay -->
  <div
    v-show="isMenuOpen"
    class="hidden md:block xl:hidden fixed w-screen h-screen bg-black/50 z-40"
    @click="isShown = false"
  />
</template>

<script setup lang="ts">
import { pixels as breakpoints } from '../tailwind.config.screens'
import { computed, ref, useAuthStore, useAvatar } from '#imports'
import { useSignInPopupProps } from '~/composables/useSignInPopupProps'
import { hostLocale, termsOfUseUrl, contactUsEmail, privacyPolicyUrl } from '~/composables/useLaoshi'
import { useWindow } from '~/composables/useWindow'

const authStore = useAuthStore()
const { email, avatar } = useAvatar()
const { windowWidth } = useWindow()
const featuredTip = useTips('featured-opened')
const isProfileOpened = ref(false)
const isShown = ref(false)
const isMenuOpen = computed(() => isShown.value || windowWidth.value >= breakpoints.xl)

const { signUpPopupProps, currentPopup, isSignUpPopupVisible, onProtectedClick } = useSignInPopupProps()
const closeOnMobile = () => {
  if (windowWidth.value < breakpoints.xl) {
    isShown.value = false
  }
}

const onLinkClick = (type?: keyof typeof signUpPopupProps.value) => {
  onProtectedClick(type || 'wordList')
  closeOnMobile()
}

const onProfileClick = () => {
  onProtectedClick('wordList', () => {
    isProfileOpened.value = true
  })
}

const onLibraryOpen = () => {
  if (!featuredTip.isTipHidden.value && !authStore.isGuest) {
    featuredTip.markTipAsHidden()
  }
  closeOnMobile()
}

watch(() => authStore.user, (val) => {
  if (val) {
    email.value = val.email
  } else {
    email.value = 'guest'
  }
})

const libraryLink = computed(() => {
  if (featuredTip.isTipHidden.value && !authStore.isGuest) {
    return '/library/?type=list'
  } else {
    return '/library/?type=featured'
  }
})
</script>
