<script setup lang="ts">
import TheSideBar from '~/components/TheSideBar.vue'
const nuxtApp = useNuxtApp()
const i18n = useI18n()
const gtag = useGtag()
const auth = useAuthStore()
gtag('event', 'page_view', {
  page_location: window.location.href,
  page_path: window.location.pathname
})

onBeforeMount(() => {
  nuxtApp.$eventBus.on('axios-error', (message: string) => {
    nuxtApp.$toast.error(i18n.t('error'), { pauseOnHover: true, autoClose: 5000 })
  })
  if (i18n.locale.value !== auth.preferences.uiLanguage && auth.preferences.uiLanguage) {
    auth.updateLanguage(auth.preferences.uiLanguage)
  }
})

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Laoshi` : 'Laoshi'
  }
})
</script>
<template>
  <div class="flex">
    <TheSideBar />
    <!-- reserve 72px for open sidebar button in mobile layout -->
    <div class="w-full xl:max-w-[1080px] p-1 md:pl-[104px] xl:px-14 md:pt-10 mb-[72px] md:mb-0">
      <div class="main-container">
        <slot />
      </div>
    </div>
  </div>
</template>
